import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import AuthContext from './AuthContext'
import Header from './components/Header'
import { BeatLoader } from 'react-spinners'
import './styles/main.css'
import { BASE_URL } from './config'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
}

const columnsList = [
  { name: 'File Name', key: 'filename', visible: true },
  { name: 'Risk Score', key: 'risk_score', visible: true },
  { name: 'ACV', key: 'acv', visible: true },
  { name: 'Auto Price Increase', key: 'auto_price_increase', visible: true },
  { name: 'Auto Renew', key: 'auto_renew', visible: true },
  { name: 'Billing Frequency', key: 'billing_frequency', visible: true },
  { name: 'Contract End Date', key: 'contract_end_date', visible: true },
  {
    name: 'Contract End Date Multi Year',
    key: 'contract_end_date_multi_year',
    visible: true,
  },
  { name: 'Contract Start Date', key: 'contract_start_date', visible: true },
  { name: 'Contract Term', key: 'contract_term', visible: true },
  { name: 'Customer Signatory', key: 'customer_signatory', visible: true },
  {
    name: 'Main Customer Contact',
    key: 'main_customer_contact',
    visible: true,
  },
  { name: 'On Short Order Form', key: 'on_short_order_form', visible: true },
  { name: 'Payment Terms', key: 'payment_terms', visible: true },
  { name: 'Price Increase Cap', key: 'price_increase_cap', visible: true },
  {
    name: 'Price Increase Cap Amount',
    key: 'price_increase_cap_amount',
    visible: true,
  },
  {
    name: 'Price Increase Percent',
    key: 'price_increase_percent',
    visible: true,
  },
  { name: 'Security Terms', key: 'security_terms', visible: true },
]

const Extract = () => {
  const [showSearchModal, setshowSearchModal] = useState(false)
  const [PopupLoader, setPopupLoader] = useState(false)
  const [SearchData, setSearchData] = useState([])
  const [currentColumn, setcurrentColumn] = useState('')
  const [currentAction, setcurrentAction] = useState('')
  const [query, setQuery] = useState('')
  const { userToken } = useContext(AuthContext)
  const [Loading, setLoading] = useState(false)
  const [fileDetailsList, setFileDetailsList] = useState([])
  const [showColumnModal, setShowColumnModal] = useState(false)
  const [columnSettings, setColumnSettings] = useState(columnsList)
  const [columnSearch, setColumnSearch] = useState('')

  useEffect(() => {
    fetchFilesDetails()
    fetchColumnSettings()
  }, [])

  const fetchFilesDetails = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${BASE_URL}/user/extract/file-list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data && response.data.status === 1) {
        setFileDetailsList(response.data.data)
      }
    } catch (error) {
      console.error('Failed to fetch file details:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchFilesDetail()
    }
  }

  const fetchFilesDetail = async () => {
    if (query) {
      setSearchData([])
      setPopupLoader(true)
      setshowSearchModal(true)
    } else {
      setLoading(true)
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/user/task/file/details`,
        {
          keyword: query,
          sort: {
            column: currentColumn,
            action: currentAction,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data && response.data.status === 1) {
        if (query) {
          var array = response.data.data.split('\n')
          setSearchData(array)
        } else {
          setshowSearchModal(false)
          setFileDetailsList(response.data.data)
        }
      }
    } catch (error) {
      console.error('Failed to fetch details:', error)
    } finally {
      setLoading(false)
      setPopupLoader(false)
    }
  }

  const fetchColumnSettings = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/column-settings`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      if (response.data && response.data.status === 1) {
        setColumnSettings(response.data.data)
      }
    } catch (error) {
      console.error('Failed to fetch column settings:', error)
    }
  }

  const saveColumnSettings = async (updatedSettings) => {
    try {
      await axios.post(
        `${BASE_URL}/user/column-settings`,
        { columnSettings: updatedSettings },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
    } catch (error) {
      console.error('Failed to save column settings:', error)
    }
  }

  const toggleColumnVisibility = (key) => {
    const updatedSettings = columnSettings.map((col) =>
      col.key === key ? { ...col, visible: !col.visible } : col
    )
    setColumnSettings(updatedSettings)
    saveColumnSettings(updatedSettings)
  }

  const filteredColumns = columnSettings.filter((col) =>
    col.name.toLowerCase().includes(columnSearch.toLowerCase())
  )

  const handleDragEnd = (result) => {
    if (!result.destination) return
    const reorderedColumns = Array.from(columnSettings)
    const [moved] = reorderedColumns.splice(result.source.index, 1)
    reorderedColumns.splice(result.destination.index, 0, moved)
    setColumnSettings(reorderedColumns)
  }

  return (
    <>
      <div className="wrapper">
        <div className="content-area px-4 pt-3">
          <header className="header-flex">
            <div></div>
            <Header />
          </header>
          <div className="cards p-4 repcardstabl">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 px-1">
                  <label className="extract_tbl_search">
                    <input
                      type="text"
                      placeholder="Search Files"
                      value={query}
                      onKeyUp={handleKeyPress}
                      onChange={(e) => {
                        setQuery(e.target.value)
                      }}
                    />
                  </label>
                  {/* <button
                    className="shortanswer"
                    onClick={() => {
                      setQuery('')
                      setcurrentColumn('')
                      setcurrentAction('')
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="grey"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-refresh-ccw"
                    >
                      <polyline points="1 4 1 10 7 10"></polyline>
                      <polyline points="23 20 23 14 17 14"></polyline>
                      <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                    </svg>
                  </button> */}
                </div>

                <div className="col-6 px-0">
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => setShowColumnModal(!showColumnModal)}
                      className="btn_ext_tbl"
                    >
                      <span>Column</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-columns"
                      >
                        <path d="M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18"></path>
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        setColumnSettings(columnsList)
                        saveColumnSettings(columnsList)
                      }}
                      className="btn_ext_tbl"
                    >
                      <span>Reset</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-rotate-ccw"
                      >
                        <polyline points="1 4 1 10 7 10"></polyline>
                        <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* {showColumnModal && (
              <div className="column-modal">
                <div className="column-modal-header">
                  <h4>Toggle Columns</h4>
                  <button
                    className="close-button"
                    onClick={() => setShowColumnModal(false)}
                  >
                    &times;
                  </button>
                </div>
                <input
                  type="text"
                  placeholder="Search column..."
                  value={columnSearch}
                  onChange={(e) => setColumnSearch(e.target.value)}
                  className="column-search"
                />
                <div className="column-section">
                  <h5>
                    Shown ({filteredColumns.filter((col) => col.visible).length}
                    )
                  </h5>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="columns-droppable">
                      {(provided) => (
                        <div
                          className="columns-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {filteredColumns
                            .filter((col) => col.visible)
                            .map((col, index) => (
                              <Draggable
                                key={col.key}
                                draggableId={col.key}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="column-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <span className="drag-icon">
                                      <FontAwesomeIcon icon={faGripVertical} />
                                    </span>
                                    <span className="column-name">
                                      {col.name}
                                    </span>
                                    <span
                                      className="eye-icon"
                                      onClick={() =>
                                        toggleColumnVisibility(col.key)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    </span>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <h5>
                    Hidden (
                    {filteredColumns.filter((col) => !col.visible).length})
                  </h5>
                  <div className="columns-list">
                    {filteredColumns
                      .filter((col) => !col.visible)
                      .map((col) => (
                        <div key={col.key} className="column-item">
                          <span className="column-name">{col.name}</span>
                          <span
                            className="eye-icon"
                            onClick={() => toggleColumnVisibility(col.key)}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )} */}
            {showColumnModal && (
              <div className="column-modal">
                <div className="column-modal-header">
                  <h5 className="col_heading_h5 mb-0">Select Columns</h5>
                  <button
                    className="col-close-button"
                    onClick={() => setShowColumnModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </div>
                <input
                  type="text"
                  placeholder="Search column..."
                  value={columnSearch}
                  onChange={(e) => setColumnSearch(e.target.value)}
                  className="column-search"
                />
                <div className="column-section">
                  <h5 className="col_heading_h5">
                    Shown ({filteredColumns.filter((col) => col.visible).length}
                    )
                  </h5>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="columns-droppable">
                      {(provided) => (
                        <div
                          className="columns-list"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{ maxHeight: '300px', overflowY: 'auto' }}
                        >
                          {filteredColumns
                            .filter((col) => col.visible)
                            .map((col, index) => (
                              <Draggable
                                key={col.key}
                                draggableId={col.key}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="column-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      padding: '8px',
                                      margin: '4px 0',
                                      backgroundColor: '#f3f3f3',
                                      border: '1px solid #ddd',
                                      borderRadius: '4px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'grab',
                                    }}
                                  >
                                    {/* <span className="drag-icon">
                                      <FontAwesomeIcon icon={faGripVertical} />
                                    </span> */}
                                    <span
                                      className="column-name"
                                      style={{ flexGrow: 1, marginLeft: '8px' }}
                                    >
                                      {col.name}
                                    </span>
                                    <span
                                      className="eye-icon"
                                      onClick={() =>
                                        toggleColumnVisibility(col.key)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </span>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <h5 className="col_heading_h5 mt-2">
                    Hidden (
                    {filteredColumns.filter((col) => !col.visible).length})
                  </h5>
                  <div className="columns-list">
                    {filteredColumns
                      .filter((col) => !col.visible)
                      .map((col) => (
                        <div
                          key={col.key}
                          className="column-item"
                          style={{
                            padding: '8px',
                            margin: '4px 0',
                            backgroundColor: '#f9f9f9',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span className="column-name" style={{ flexGrow: 1 }}>
                            {col.name}
                          </span>
                          <span
                            className="eye-icon"
                            onClick={() => toggleColumnVisibility(col.key)}
                          >
                            <FontAwesomeIcon icon={faEyeSlash} />
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}

            <div className="tabrapes_scrl mt-3  border_radi_eigh">
              <table
                className="rpt_tables task_tabl tble_extrcts mt-0"
                style={{ width: '100%' }}
              >
                <thead>
                  <tr>
                    <th>Select</th>
                    {columnSettings
                      .filter((col) => col.visible)
                      .map((col) => (
                        <th key={col.key}>{col.name}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {Loading && (
                    <tr>
                      <td colSpan={columnSettings.length + 1}>
                        <BeatLoader
                          color={'#000'}
                          loading={Loading}
                          cssOverride={override}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </td>
                    </tr>
                  )}
                  {!Loading && fileDetailsList.length === 0 && (
                    <tr>
                      <td colSpan={columnSettings.length + 1}>
                        No records found.
                      </td>
                    </tr>
                  )}
                  {!Loading &&
                    fileDetailsList.length > 0 &&
                    fileDetailsList.map((file) => (
                      <tr key={file._id}>
                        <td>
                          <div className="d-flex">
                            <input type="checkbox" />
                          </div>
                        </td>
                        {columnSettings
                          .filter((col) => col.visible)
                          .map((col) => (
                            <td key={col.key}>{file[col.key] || 'N/A'}</td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {showSearchModal && (
          <div className="modal fade show" id="documentModal" role="dialog">
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div
                className="modal-content border-none p-3"
                style={{ maxHeight: 700, overflow: 'scroll' }}
              >
                <div className="modal-body p-0">
                  <div className="container px-3 py-2">
                    <div className="row">
                      <div className="col-8">
                        <h2 className="modal-headings pt-1 m-0">
                          Search Results
                        </h2>
                      </div>
                      <div className="col-4 text-right">
                        <button
                          className="btn_mdl_close"
                          onClick={() => setshowSearchModal(false)}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      {PopupLoader && (
                        <div className="col-12 pt-2">
                          <BeatLoader
                            color={'#000'}
                            loading={PopupLoader}
                            cssOverride={override}
                            size={10}
                          />
                        </div>
                      )}
                      <div className="col-12 extct_mdl_ul">
                        <ul className='p-0 mt-3'>
                          {SearchData.map(
                            (item, key) => item && <li key={key}>{item}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Extract
