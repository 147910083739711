import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import "../styles/main.css";
import { BeatLoader } from "react-spinners";
import AuthContext from "../AuthContext";

const override = {
  display: "flex",
  margin: "0 auto",
  justifyContent: "center",
  alignItems: "center",
};

const Clauses = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userToken } = useContext(AuthContext);

  // Filters
  const [filterClauseName, setFilterClauseName] = useState("");
  const [filterDocumentName, setFilterDocumentName] = useState("");
  const [filterCategory, setFilterCategory] = useState("");

  const fetchFileDetails = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/global/file-details/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        const { categoryName } = response.data.data;
        return {
          category_name: categoryName || "N/A",
        };
      }
    } catch (error) {
      console.error("Error fetching file details:", error);
      return {};
    }
  };

  const fetchAllFileDetails = async (fileList) => {
    const fileDetailsPromises = fileList.map(async (file) => {
      const details = await fetchFileDetails(file._id);
      return { ...file, ...details };
    });
    const allDetails = await Promise.all(fileDetailsPromises);
    return allDetails;
  };

  const fetchClauseDetailsForDoc = async (fileId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/getClauses`,
        { file_id: fileId },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        return response.data.data;
      } else {
        console.error("Failed to fetch clauses:", response.data.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching clauses:", error);
      return null;
    }
  };

  const fetchAllClausesForDocs = async (docs) => {
    const clausePromises = docs.map(async (doc) => {
      const details = await fetchClauseDetailsForDoc(doc._id);
      return { ...doc, clauseDetails: details || {} };
    });

    const docsWithClauses = await Promise.all(clausePromises);
    return docsWithClauses;
  };

  const fetchDocuments = async () => {
    setIsLoading(true);
    try {
      // Fetch initial page to get totalPages
      let response = await axios.post(
        `${BASE_URL}/user/file/list`,
        {
          page: 1,
          size: 10,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status !== 1) {
        console.error("Failed to fetch documents:", response.data.message);
        setIsLoading(false);
        return;
      }

      let fileList = response.data.data;
      let totalPages = response.data.totalPages;

      // If there are more pages, fetch them all
      for (let page = 2; page <= totalPages; page++) {
        let pageResponse = await axios.post(
          `${BASE_URL}/user/file/list`,
          {
            page: page,
            size: 10,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (pageResponse.data.status === 1) {
          fileList = fileList.concat(pageResponse.data.data);
        } else {
          console.error(
            "Failed to fetch documents on page",
            pageResponse.data.message
          );
        }
      }

      const sortedFiles = fileList.sort((a, b) => b.added_date - a.added_date);
      const filesWithDetails = await fetchAllFileDetails(sortedFiles);
      const filesWithClauses = await fetchAllClausesForDocs(filesWithDetails);
      setDocuments(filesWithClauses);
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Build a flat list of clause rows for all documents
  const clauseRows = documents.flatMap((doc) => {
    if (!doc.clauseDetails || Object.keys(doc.clauseDetails).length === 0) {
      return [];
    }
    return Object.entries(doc.clauseDetails).map(
      ([clauseName, clauseText]) => ({
        clauseName,
        category: doc.category_name,
        documentName: doc.filename,
        clauseText: clauseText.replace(/_/g, '"'),
      })
    );
  });

  // Apply filters to the flat clause rows
  const filteredRows = clauseRows.filter((row) => {
    const clauseNameMatch = filterClauseName
      ? row.clauseName.toLowerCase().includes(filterClauseName.toLowerCase())
      : true;

    const documentNameMatch = filterDocumentName
      ? row.documentName
          .toLowerCase()
          .includes(filterDocumentName.toLowerCase())
      : true;

    const categoryMatch = filterCategory
      ? row.category?.toLowerCase().includes(filterCategory.toLowerCase())
      : true;

    return clauseNameMatch && documentNameMatch && categoryMatch;
  });

  return (
    <div className="wrapper">
      <div className="container mt-4">
        <h3 className="title w-700 mt-0">Clauses</h3>

        {/* Filters */}
        <div className="row mb-3">
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Clause Name"
              value={filterClauseName}
              onChange={(e) => setFilterClauseName(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Document Name"
              value={filterDocumentName}
              onChange={(e) => setFilterDocumentName(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <input
              type="text"
              className="form-control"
              placeholder="Filter by Category"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            />
          </div>
        </div>

        {isLoading ? (
          <div className="text-center">
            <BeatLoader
              color={"#000"}
              loading={isLoading}
              cssOverride={override}
              size={10}
            />
          </div>
        ) : (
          <>
            <table className="task_tabl">
              <thead>
                <tr>
                  <th>Clause Name</th>
                  <th>Category</th>
                  <th>Document Name</th>
                  <th>Clause Text</th>
                </tr>
              </thead>
              <tbody>
                {filteredRows.length === 0 ? (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No documents found.
                    </td>
                  </tr>
                ) : (
                  filteredRows.map((row, index) => (
                    <tr key={index}>
                      <td>{row.clauseName}</td>
                      <td>{row.category}</td>
                      <td>{row.documentName}</td>
                      <td>{row.clauseText}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};

export default Clauses;
