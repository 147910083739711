import React, { useState } from 'react'
import axios from 'axios'
import '../../styles/Tools.css' // Ensure you have appropriate styles
import { BASE_URL } from '../../config'

export default function Tools() {
  const [activeTab, setActiveTab] = useState('convertPdfToDocx')
  const [selectedFile, setSelectedFile] = useState(null)
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const handleTabChange = (tab) => {
    setActiveTab(tab)
    setError('')
    setSuccessMessage('')
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleConvertAndDownload = async () => {
    if (!selectedFile) {
      setError('Please select a PDF file to upload.')
      return
    }
    setError('')
    setSuccessMessage('')

    try {
      const formData = new FormData()
      formData.append('pdfFile', selectedFile)

      const response = await axios.post(
        `${BASE_URL}/convert-pdf-to-word`,
        formData,
        {
          responseType: 'blob',
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )

      // Handle file download
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'converted.docx')
      document.body.appendChild(link)
      link.click()
      setSuccessMessage('PDF successfully converted to Word!')
    } catch (err) {
      console.error('Error converting PDF:', err)
      setError('Failed to convert PDF to Word. Please try again.')
    }
  }

  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-4" style={{ marginLeft: '0px' }}>
        <div className="tools-page">
          <h3 className="title w-700 mt-0">Tools</h3>
          <div className="cards p-3">
            <div className="tools-nav">
              <button
                onClick={() => handleTabChange('convertPdfToDocx')}
                className={activeTab === 'convertPdfToDocx' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  className="feather feather-file-text"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
                &nbsp;&nbsp; Convert PDF to Docx
              </button>
            </div>
            <div className="tab-content pt-0 px-2 tabss_cnts pb-0">
              {activeTab === 'convertPdfToDocx' && (
                <div className="convert-tab">
                  <h2 className="cnverts_hsds mt-0">Convert PDF to Docx</h2>
                  <div className="file-upload-container">
                    <label className="file-upload-label">
                      <input
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                      />
                      <span>Upload PDF</span>
                    </label>
                    {selectedFile && <p>{selectedFile.name}</p>}
                  </div>
                  <button
                    className="edit-button"
                    onClick={handleConvertAndDownload}
                  >
                    Convert and Download
                  </button>
                  {error && <p className="error-message">{error}</p>}
                  {successMessage && (
                    <p className="success-message">{successMessage}</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
