import React, { useState, useContext } from 'react'
import axios from 'axios'
import logo from '../img/logo-svg.png'
import { Link } from 'react-router-dom'
import AuthContext from '../AuthContext'
import { BASE_URL } from '../config'
import '../styles/Auth.css'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const [step, setStep] = useState(1) // 1 = login, 2 = OTP verification

  const { login } = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(`${BASE_URL}/user/login`, {
        email,
        password,
      })
      if (response.data.status === 1) {
        const { token, role, userId, fname, lname } = response.data.data
        login(email, token, role, userId, fname, lname)
      } else if (response.data.status === 2) {
        // 2FA required
        setStep(2)
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to sign in')
    }
  }

  const handleOtpSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post(`${BASE_URL}/user/verify/email/otp`, {
        email,
        otp,
      })

      if (response.data.status === 1) {
        const { token, role } = response.data.data
        login(email, token, role)
      } else {
        setError(response.data.message)
      }
    } catch (err) {
      setError('Failed to verify OTP')
    }
  }

  return (
    <div className=" pt-0">
      <div className='logo_wrps'>
      </div>
      <h2 className="login-heading">Log in</h2>
      {error && <p className="error">{error}</p>}
      {step === 1 ? (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter email"
            />
          </div>
          <div className="form-group mb-2">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Enter password"
            />
          </div>
          <div className="d-flex align-items-center mb-2 pb-2 mt-2">
            <div className="pt-1 me-2">
              <input className="m-0" type="checkbox" required />
            </div>
            <div className='term_s'>
              <span style={{ fontSize: '12px' }}>
                Agree to <Link to="/terms">Terms & Conditions</Link> and{' '}
                <Link to="/privacy">Privacy Policy</Link>
              </span>
            </div>
          </div>
          <div>
            <button type="submit" className="auth-btn m-0">
              Log in
            </button>
          </div>
          <div className="mt-2 for_passwrd">
            <Link to="/forgot-password">Forgot Password?</Link>{' '}
            {/* Link to the forget password page */}
          </div>
        </form>
      ) : (
        <form onSubmit={handleOtpSubmit}>
          <div className="form-group">
            <label>OTP:</label>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              placeholder="Enter OTP sent to your email"
            />
          </div>
          <button type="submit" className="auth-btn m-0">
            Verify OTP
          </button>
        </form>
      )}
    </div>
  )
}
