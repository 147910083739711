import React, { useEffect, useState, useContext } from 'react'
import ApexCharts from 'react-apexcharts'
import AuthContext from '../AuthContext'
import axios from 'axios'
import { BASE_URL } from '../config'
import '../styles/main.css' // Assuming your CSS file

const Chart = () => {
  const { userToken } = useContext(AuthContext)
  const { userRole } = useContext(AuthContext)
  const [documentData, setDocumentData] = useState({
    nda: [],
    msa: [],
    dpa: [],
    months: [],
  })

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/${userRole}/files/document-count-per-category`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        const data = response.data.data

        // Get unique months from the data, prioritizing NDA, MSA, and DPA.
        let months = Object.keys(data['Non Disclosure Agreement (NDA)'] || {})
        if (months.length === 0) {
          months = Object.keys(data['Master Services Agreement (MSA)'] || {})
        }
        if (months.length === 0) {
          months = Object.keys(data['Data Processing Agreement (DPA)'] || {})
        }
        months = months.sort() // Sort months for proper display.

        const nda = months.map(
          (month) => data['Non Disclosure Agreement (NDA)']?.[month] || 0
        )
        const msa = months.map(
          (month) => data['Master Services Agreement (MSA)']?.[month] || 0
        )
        const dpa = months.map(
          (month) => data['Data Processing Agreement (DPA)']?.[month] || 0
        )

        setDocumentData({ nda, msa, dpa, months })
      } catch (error) {
        console.error('Error fetching document data:', error)
      }
    }

    fetchDocumentData()
  }, [userToken, userRole])

  const options = {
    series: [
      { name: 'NDA', data: documentData.nda },
      { name: 'MSA', data: documentData.msa },
      { name: 'DPA', data: documentData.dpa },
    ],
    chart: { height: 350, type: 'area' },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    xaxis: {
      type: 'category',
      categories: documentData.months,
    },
    tooltip: {
      x: { format: 'MM/yyyy' },
    },
  }

  // Check if there's any data to display
  const hasData =
    documentData.nda.length > 0 ||
    documentData.msa.length > 0 ||
    documentData.dpa.length > 0

  return (
    <div className="chart-wrapper">
      <h2 className="chart-title">Total Documents per Category Over Time</h2>
      <div id="chart" className="chart-container">
        {!hasData && (
          <div className="overlay">
            <p className="overlay-text">No data available</p>
          </div>
        )}
        <div className={hasData ? '' : 'blur-chart'}>
          <ApexCharts
            options={options}
            series={options.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  )
}

export default Chart
