import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../../AuthContext'
import { BASE_URL } from '../../config'
import Mammoth from 'mammoth'
import { renderAsync } from 'docx-preview'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import '../../styles/DocumentEditor.css'

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.mjs`

export default function DocumentEditor({ fileId, handleBack }) {
  const { userToken, userRole } = useContext(AuthContext)
  const [documentUrl, setDocumentUrl] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedText, setSelectedText] = useState('')
  const [replacementText, setReplacementText] = useState('')
  const containerRef = useRef(null)
  const [numPages, setNumPages] = useState(null)
  const [isPdf, setIsPdf] = useState(false)
  const originalContentRef = useRef('') // Keep track of original HTML content
  const currentSearch = localStorage.getItem('currentSearch')
  const viewerRef = useRef(null)

  useEffect(() => {
    fetchDocument()
    return () => {
      // Clean up viewer content on component unmount
      if (viewerRef.current) {
        viewerRef.current.innerHTML = ''
      }
    }
  }, [fileId, userToken, userRole])

  const fetchDocument = async () => {
    try {
      const documentUrl =
        userRole === 'user'
          ? `${BASE_URL}/user/file/view/${fileId}`
          : `${BASE_URL}/global/file/view/${fileId}`
      const response = await axios.get(documentUrl, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })
      if (response.status === 200) {
        setDocumentUrl(response.data.url)
        if (response.data.url.includes('.pdf')) {
          setIsPdf(true)
          await convertPdfToWord(response.data.url) // Convert PDF to Word
        } else if (response.data.url.includes('.docx')) {
          setIsPdf(false)
          await renderDocx(response.data.url)
        } else {
          alert('Unsupported file format.')
        }
      }
    } catch (error) {
      console.error('Error fetching document:', error)
    } finally {
      setLoading(false)
    }
  }

  const renderDocx = async (docxUrl) => {
    try {
      const response = await fetch(docxUrl)
      if (!response.ok) throw new Error('Failed to fetch file')
      const arrayBuffer = await response.arrayBuffer()

      // Convert docx to HTML using Mammoth
      const result = await Mammoth.convertToHtml({ arrayBuffer })
      originalContentRef.current = result.value

      // Ensure containerRef is not null
      if (!containerRef.current) {
        console.error('containerRef is null. Ensure the container is rendered.')
        return
      }

      // Render using docx-preview for better formatting
      await renderAsync(arrayBuffer, containerRef.current)

      // Add event listener for text selection
      containerRef.current.addEventListener('mouseup', handleTextSelection)
    } catch (error) {
      console.error('Error rendering DOCX file:', error)
      alert('Failed to render Word document. Please try again.')
    }
  }

  const handleTextSelection = () => {
    const selection = window.getSelection()
    if (!selection || selection.rangeCount === 0) return
    const text = selection.toString()
    if (text.trim()) {
      setSelectedText(text)
      setReplacementText(text)
    }
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const handleReplaceText = () => {
    if (!selectedText || !replacementText) return

    const containerHTML = containerRef.current.innerHTML
    const updatedHTML = containerHTML.replace(selectedText, replacementText)
    containerRef.current.innerHTML = updatedHTML

    // Clear selection and reset states
    setSelectedText('')
    setReplacementText('')
    window.getSelection().removeAllRanges()
  }

  const convertPdfToWord = async (pdfUrl) => {
    try {
      // Fetch the binary PDF file
      const response = await fetch(pdfUrl)
      if (!response.ok) throw new Error('Failed to fetch PDF file')

      const pdfBlob = await response.blob()

      // Create FormData to send the PDF file
      const formData = new FormData()
      formData.append('pdfFile', pdfBlob, 'uploaded.pdf')

      // Send the PDF to the backend for conversion
      const convertResponse = await axios.post(
        `${BASE_URL}/convert-pdf-blob-to-word`,
        formData,
        {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      console.log('Convert response:', convertResponse)
      // Handle the converted Word document
      const wordFile = new Blob([convertResponse.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      })
      const wordFileUrl = URL.createObjectURL(wordFile)
      console.log('Word file URL:', wordFileUrl)
      await renderDocx(wordFileUrl) // Render the Word content for editing
    } catch (error) {
      console.error('Error converting PDF to Word:', error.message)
      alert('Failed to convert PDF to Word. Please try again.')
    }
  }

  const handleSave = async () => {
    try {
      const updatedContent = containerRef.current.innerHTML

      const action = window.confirm(
        'Replace original file? Click Cancel to save as a new file.'
      )
        ? 'replace'
        : 'saveAsNew'

      const format =
        action === 'saveAsNew'
          ? window.prompt('Enter file format (pdf/docx):', 'docx')
          : isPdf
          ? 'pdf'
          : 'docx'

      const response = await axios.post(
        `${BASE_URL}/global/file/save-or-replace`,
        {
          fileId,
          action,
          format,
          updatedContent,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
          responseType: 'blob', // Ensure correct handling of binary data
        }
      )

      if (response.status === 200) {
        const fileBlob = new Blob([response.data], {
          type:
            format === 'pdf'
              ? 'application/pdf'
              : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const fileUrl = URL.createObjectURL(fileBlob)

        // Open or download the updated file
        // const anchor = document.createElement('a')
        // anchor.href = fileUrl
        // anchor.download = `updated.${format}`
        // anchor.click()
      }
    } catch (error) {
      console.error('Error saving file:', error.message)
      alert('Error saving file. Please try again.')
    }
  }

  useEffect(() => {
    if (!containerRef.current) {
      console.log('containerRef is null during render.')
    } else {
      console.log('containerRef initialized:', containerRef.current)
    }
  }, [])
  return (
    <div className="document-editor-page">
      <div className="editor-header">
        <div className="d-flex">
          <button className="go-back2 mx-4 mt-4" onClick={handleBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>{' '}
            &nbsp; Back to Documents
          </button>
        </div>
        <div className="d-flex">
          <button className="go-back2 mx-4 mt-4" onClick={handleSave}>
            Save Document
          </button>
        </div>
      </div>
      <div className="editor-container">
        <div className="document-view-pane">
          {loading ? (
            <p>Loading document...</p>
          ) : (
            <div className="document-viewer pt-0">
              {documentUrl && (
                <div
                  style={{
                    height: '100vh',
                    paddingTop: '20px',
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <div
                      ref={containerRef}
                      style={{
                        padding: '0',
                        height: '80vh',
                        overflow: 'auto',
                        fontFamily: "'Times New Roman', serif",
                        whiteSpace: 'pre-wrap',
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="editor-sidebar">
          <h3>Edit Selected Text</h3>
          <textarea
            className="selected-text-input"
            value={replacementText}
            onChange={(e) => setReplacementText(e.target.value)}
            placeholder="Selected text will appear here..."
          ></textarea>
          <button className="replace-button" onClick={handleReplaceText}>
            Replace
          </button>
        </div>
      </div>
    </div>
  )
}
