import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import AuthContext from '../../AuthContext'
import '../../styles/Documents.css'
import SimpleDocumentView from '../../SimpleDocumentViewer'
import { BASE_URL } from '../../config'
import Pagination from '../Pagination'
import { BeatLoader } from 'react-spinners'
import Report from '../Reports' // Import the Report component
import Header from '../Header'
import DocumentEditor from '../utils/DocumentEditor'
import ErrorBoundary from '../utils/ErrorBoundary'

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
}
const Documents = () => {
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedFileId, setSelectedFileId] = useState(null) // State to track the selected file
  const [selectFileToEdit, setSelectFileToEdit] = useState(null)
  const { userToken, userRole } = useContext(AuthContext)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, settotalPages] = useState(0)
  const [activeTab, setActiveTab] = useState('documents') // New state for active tab
  // const [sharedFiles, setSharedFiles] = useState([])

  const [searchKeyword, setSearchKeyword] = useState('')

  const handleTabChange = (tab) => {
    setActiveTab(tab)
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    fetchFiles(page)
  }
  useEffect(() => {
    const isSearchUser = localStorage.getItem('isSearchUser')
    if (isSearchUser) {
      setSelectedFileId(isSearchUser)
      localStorage.removeItem('isSearchUser')
    }

    fetchFiles()
    // fetchSharedDocuments()
  }, [userToken, userRole])
  const fetchFiles = async (current) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/allFiles/list`,
        {
          page: current,
          size: '10',
          keyword: searchKeyword
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        console.log(response.data)
        settotalPages(response.data.totalPages)
        setFiles(response.data.data)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error fetching files:', error)
      setError('Failed to fetch files')
    } finally {
      setLoading(false)
    }
  }

  // const fetchSharedDocuments = async (current = 1) => {
  //   setLoading(true)
  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/user/sharedDocuments`,
  //       { page: current, size: '10' },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${userToken}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     )
  //     if (response.data.status === 1 && response.data.data.length > 0) {
  //       settotalPages(response.data.totalPages)
  //       setSharedFiles(response.data.data)
  //     } else {
  //       setSharedFiles([]) // Set empty array if no shared files
  //     }
  //   } catch (error) {
  //     console.error('Error fetching shared documents:', error)
  //     setSharedFiles([]) // Set empty array if an error occurs
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const handleViewFile = (fileId) => {
    setSelectedFileId(fileId) // Set the selected file ID
  }
  const handleEdit = (fileId) => {
    setSelectFileToEdit(fileId)
  }

  const handleBack = () => {
    setSelectedFileId(null) // Reset to go back to the documents list
    setSelectFileToEdit(null)
  }
  if (selectFileToEdit) {
    return (
      <ErrorBoundary>
        <DocumentEditor fileId={selectFileToEdit} handleBack={handleBack} />
      </ErrorBoundary>
    )
  }
  if (selectedFileId) {
    // If a file is selected, show the SimpleDocumentView component
    return (
      <SimpleDocumentView fileId={selectedFileId} handleBack={handleBack} />
    )
  }

  // if (loading) {
  //   return <p>Loading files...</p>;
  // }

  if (error) {
    return <p className="error">{error}</p>
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setLoading(true)
      fetchFiles(1);
      // console.log(searchKeyword);
    }
  };
  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-4" style={{ marginLeft: '0px' }}>
        <header className="header-flex">
          <div></div>
          <Header />
        </header>
        <div className="">
          <div className="documents-container">
            <div className="doc_nav_tbas_buttons">
              {/* Navigation bar for switching between tabs */}
              <button
                onClick={() => handleTabChange('documents')}
                className={activeTab === 'documents' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-file"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
                &nbsp;&nbsp; Documents
              </button>
              <button
                onClick={() => handleTabChange('reports')}
                className={activeTab === 'reports' ? 'active' : ''}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-pie-chart"
                >
                  <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                  <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                </svg>
                &nbsp;&nbsp; Reports
              </button>
              {/* {sharedFiles.length > 0 && (
                <button
                  onClick={() => handleTabChange('shared')}
                  className={activeTab === 'shared' ? 'active' : ''}
                >
                  Shared Documents
                </button>
              )} */}
            </div>

            {/* Conditional rendering based on active tab */}
            {activeTab === 'documents' && (
              <div className="px-1">
                <div className="cards mt-2">
                  {/* <h2 className="mx-0 mt-4 color-blk font_heading_doc" style={{float:"left"}}>
                    Your Documents
                  </h2> */}
                  <div className='col-3 mx-0 p-3' style={{ float: "left" }}>
                    <input type='text' className='form-control' placeholder='Search here'
                      onKeyUp={(e) => {
                        setSearchKeyword(e.target.value);
                        handleKeyPress(e)
                      }
                      } />
                  </div>


                  <table className="documents-table task_tabl">
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th>Category</th>
                        <th>Submit Date</th>
                        <th>Risk Score (%)</th>
                        <th>Owned By</th>
                        <th className="wid_40pxls text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <tr>
                          <td colSpan={6}>
                            <BeatLoader
                              color={'#000'}
                              loading={loading}
                              cssOverride={override}
                              size={10}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </td>
                        </tr>
                      )}
                      {!loading && files.length == 0 && (
                        <tr>
                          <td colSpan={6}>No files found.</td>
                        </tr>
                      )}
                      {!loading &&
                        files.map((file) => (
                          <tr key={file._id}>
                            <td>{file.filename}</td>
                            <td>{file.qc_id?.name || 'N/A'}</td>
                            <td>
                              {new Date(file.added_date).toLocaleDateString()}
                            </td>
                            <td>
                              {file.ai_risk_score != null
                                ? `${(file.ai_risk_score * 100).toFixed(2)}%`
                                : 'N/A'}
                            </td>
                            <td>{file.user_id?.fname || 'Unknown'}</td>
                            <td>
                              <button
                                className="btn_td_tabs drp_btn_tds wid_40pxls p-0 dropdown-toggle"
                                type="button"
                                id={`actionDropdown-${file._id}`}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-more-vertical"
                                >
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="12" cy="5" r="1"></circle>
                                  <circle cx="12" cy="19" r="1"></circle>
                                </svg>
                              </button>
                              <ul
                                className="dropdown-menu actions_ulss"
                                aria-labelledby={`actionDropdown-${file._id}`}
                              >
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleViewFile(file._id)}
                                  >
                                    <span className="tooltips_view">View</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-eye"
                                    >
                                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                    View
                                  </button>
                                </li>
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleEdit(file._id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      className="feather feather-file-text"
                                    >
                                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                      <polyline points="14 2 14 8 20 8"></polyline>
                                      <line
                                        x1="16"
                                        y1="13"
                                        x2="8"
                                        y2="13"
                                      ></line>
                                      <line
                                        x1="16"
                                        y1="17"
                                        x2="8"
                                        y2="17"
                                      ></line>
                                      <polyline points="10 9 9 9 8 9"></polyline>
                                    </svg>
                                    Edit
                                  </button>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <br />
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            )}
            {activeTab === 'reports' && (
              <div className="reports-container">
                <Report />
              </div>
            )}
            {/* {activeTab === 'shared' && sharedFiles.length > 0 && (
              <div className="cards mt-2">
                <h2 className="mx-0 mt-4 color-blk font_heading_doc">
                  Shared Documents
                </h2>
                <table className="documents-table task_tabl">
                  <thead>
                    <tr>
                      <th>Document Name</th>
                      <th>Category</th>
                      <th>Submit Date</th>
                      <th>Risk Score (%)</th>
                      <th>Owned By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan={6}>
                          <BeatLoader
                            color={'#000'}
                            loading={loading}
                            cssOverride={override}
                            size={10}
                          />
                        </td>
                      </tr>
                    )}
                    {!loading && sharedFiles.length === 0 && (
                      <tr>
                        <td colSpan={6}>No shared files found.</td>
                      </tr>
                    )}
                    {!loading &&
                      sharedFiles.map((file) => (
                        <tr key={file._id}>
                          <td>{file.filename}</td>
                          <td>{file.qc_id?.name || 'N/A'}</td>
                          <td>
                            {new Date(file.added_date).toLocaleDateString()}
                          </td>
                          <td>
                            {file.ai_risk_score != null
                              ? `${(file.ai_risk_score * 100).toFixed(2)}%`
                              : 'N/A'}
                          </td>
                          <td>{file.user_id?.fname || 'Unknown'}</td>
                          <td>
                            <button
                              className="button-send"
                              onClick={() => handleViewFile(file._id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-eye"
                              >
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Documents
