import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../styles/Settings.css'
import PasswordSettings from './PasswordSettings'
import axios from 'axios'
import companyLogo from '../../img/organization.png' // Default company logo
import { useContext } from 'react'
import AuthContext from '../../AuthContext'
import { BASE_URL } from '../../config'
import { toast } from "react-toastify";
import { Modal, Button, Card,Col, Row, FloatingLabel } from 'react-bootstrap';



// Modal.setAppElement('#root') // Avoid accessibility warning from Modal

export default function SlackConnect() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const [channelList, setChannelList] = useState([]);
  const [userChannelId, setUserChannelId] = useState('');

  const { userToken, userEmail, userRole } = useContext(AuthContext);
  const navigate = useNavigate()
  useEffect(() => {
    linkSlack();
  }, [userToken])


  const linkSlack = async () => {
    try {
      if (code) {
        const response = await axios.post(
          `${BASE_URL}/user/slc/link-account`,
          { code: code },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        )
        if (response.data.status) {
          toast.success(response.data.message);
          getChannels();
          // navigate('/settings');
        }
        else {
          toast.error(response.data.message);
          // navigate('/settings');
        }
      }
      else {
        toast.error('Invalid request');
        // navigate('/settings');
      }
    } catch (error) {
      toast.error('Try again');
      // navigate('/settings');
      // console.error('Error fetching slack info:', error)
    }
  }

  const getChannels = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/slc/get-channel`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      if (response.data.status) {
        if (response.data.data.ok) {
          setChannelList(response.data.data.channels);
          setUserChannelId(response.data.user_channel);
          handleChannelShow()
        }
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.log('error: ', error);
      toast.error('Try again');
      // console.error('Error fetching slack info:', error)
    }
  }

  const connectChannel = async (id) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/slc/connect-channel`,
        {channel_id: id},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      if (response.data.status) {
        getChannels();
        toast.success(response.data.message);
        navigate('/settings');
      }
      else {
        toast.error(response.data.message);
      }

    } catch (error) {
      console.log('error: ', error);
      toast.error('Try again');
      // console.error('Error fetching slack info:', error)
    }
  }
  
  const [showChannelModal, setShowChannelModal] = useState(false);
  const handleChannelClose = () => setShowChannelModal(false);
  const handleChannelShow = () => {
    getChannels();
    setShowChannelModal(true);
    console.log(channelList);
  };
  // setShowChannelModal(true);

  return (
    <div className="wrapper">
      <div className="content-area px-4 pt-4" style={{ marginLeft: '0px' }}>
        <div className="settings-page pt-0">
          <h3 className="title w-700 mt-0">Slack connect</h3>
          <div className="cards px-4 pt-4 pb-2 mb-5 mt-3">
            <center>Connecting to slack...</center>
            {/* <Button variant="primary" onClick={handleChannelShow}>
              Open Modal
            </Button> */}
          </div>
        </div>
      </div>

      <Modal show={showChannelModal} onHide={handleChannelClose}>
        <Modal.Header closeButton>
          <Modal.Title>Channels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(channelList && channelList.length) ? (
            <>
              {/* <ul> */}
                {channelList.map((ch, index) => (
                  <Card key={index} className='px-2 py-2 my-2'>
                    <Row>

                      <Col md='9' className='float-start'>
                      <FloatingLabel>
                      {ch.name}
                      </FloatingLabel>
                      </Col>
                      <Col md='2' className='float-end'>
                        {userChannelId != ch.id ? (
                          <Button className='readline-btn-down py-1 my-0' onClick={() => {connectChannel(ch.id)}}>Add</Button>
                        ) : (
                          <><span className='text-success'>Added</span></>
                        )}
                      </Col>
                    </Row>
                  </Card>
                  // <li key={index}>{ch.name} <span className='readline-btn-down mx-1' style={{position:'absolute', right:'20px'}}>Add</span></li>
                ))}
              {/* </ul> */}
            </>
          ) : (
            <>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
        
        </Modal.Footer>
      </Modal>
    </div>
  )
}
