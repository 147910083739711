import React, { useState } from 'react'
import '../styles/main.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useContext, useEffect } from 'react'
import AuthContext from '../AuthContext'
import { BASE_URL } from '../config'

const Invite = () => {
  const [activeTab, setActiveTab] = useState('details')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const { userToken, userRole } = useContext(AuthContext)
  const [companyName, setCompanyName] = useState('')
  const [documentAccess, setDocumentAccess] = useState(false) // Toggle for dropdown
  const [selectedDocs, setSelectedDocs] = useState([]) // Array to store selected documents
  const [dropdownOpen, setDropdownOpen] = useState(false) // Toggle for dropdown visibility
  const [totalPages, settotalPages] = useState(0)
  const [files, setFiles] = useState([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    console.log(activeTab)
  }, [activeTab])

  const goToAccount = () => {
    navigate('/accounts')
  }

  useEffect(() => {
    fetchFiles()
  }, [userToken, userRole])

  const fetchFiles = async (current) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${userRole}/allFiles/list`,
        {
          page: current,
          size: '10',
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      if (response.data.status === 1) {
        console.log(response.data)
        settotalPages(response.data.totalPages)
        setFiles(response.data.data)
      } else {
        setError(response.data.message)
      }
    } catch (error) {
      console.error('Error fetching files:', error)
      setError('Failed to fetch files')
    } finally {
      setLoading(false)
    }
  }

  const handleNextClick = () => {
    if (activeTab === 'details') {
      // Extract company name from email before moving to the next step
      const emailParts = email.split('@')[1]?.split('.com')[0]
      setCompanyName(emailParts ? emailParts : 'Unknown Company')
      setActiveTab('role')
    } else if (activeTab === 'role') {
      setActiveTab('review')
    }
  }

  const handleBackClick = () => {
    if (activeTab === 'role') {
      setActiveTab('details')
    } else if (activeTab === 'review') {
      setActiveTab('role')
    }
  }

  const handleSubmitInvite = async () => {
    const missingFields = []
    if (!firstName) missingFields.push('First Name')
    if (!lastName) missingFields.push('Last Name')
    if (!email) missingFields.push('Email')
    if (!role) missingFields.push('Role')
    if (missingFields.length > 0) {
      alert(`Please fill the following fields: ${missingFields.join(', ')}`)
      return
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/user/invite`,
        {
          firstName,
          lastName,
          email,
          permission: role, //read-only, admin, user
          companyName,
          documents: selectedDocs, // Pass selected documents
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      const { status, message } = response.data
      if (status === 1) {
        alert('User invited successfully')
      } else {
        alert(`Error inviting user: ${message}`)
      }
    } catch (error) {
      console.error('Error inviting user:', error)
      alert('There was an error inviting the user. Please try again later.')
    }
  }

  // Handle document selection
  const handleDocumentToggle = (docId) => {
    setSelectedDocs((prevDocs) =>
      prevDocs.includes(docId)
        ? prevDocs.filter((item) => item !== docId)
        : [...prevDocs, docId]
    )
  }

  return (
    <>
      <div className="container-fluid min-hei-100vh">
        <div className="row">
          <div className="col-6 py-4 px-5 text-right" style={{ width: '80vw' }}>
            <button className="c_btn_cross" onClick={goToAccount}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#70707d"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
        </div>

        <div className="row px-5 mt-5">
          <div className="col-12 px-5">
            <div
              className="d-flex align-items-start"
              style={{ display: 'flex' }}
            >
              {/* Sidebar navigation for the steps */}
              <div
                className="nav flex-column nav-pills me-3 nav_brd_left"
                style={{
                  height: '154px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <button
                  className={`nav-link ${
                    activeTab === 'details' ? 'active' : ''
                  }`}
                  onClick={() => setActiveTab('details')}
                >
                  Invite details
                </button>
                <button
                  className={`nav-link ${activeTab === 'role' ? 'active' : ''}`}
                  onClick={() => setActiveTab('role')}
                >
                  Assign role
                </button>
                <button
                  className={`nav-link ${
                    activeTab === 'review' ? 'active' : ''
                  }`}
                  onClick={() => setActiveTab('review')}
                >
                  Review
                </button>
              </div>

              {/* Tab content for Invite, Assign Role, and Review */}
              <div className="tab-content bg_dark_trnd">
                {activeTab === 'details' && (
                  <div className="tab-pane active">
                    <h4 className="invite_heading">
                      Who do you want to invite?
                    </h4>
                    <div className="max_width_1000">
                      <div className="row pt-4">
                        <div className="col-6" style={{ width: '100%' }}>
                          <label className="lbl2">Legal first name</label>
                          <input
                            type="text"
                            className="inv_input"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="col-6 mt-3" style={{ width: '100%' }}>
                          <label className="lbl2">Legal last name</label>
                          <input
                            type="text"
                            className="inv_input"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-12" style={{ width: '100%' }}>
                          <label className="lbl2">Email</label>
                          <input
                            type="text"
                            className="inv_input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row pt-5 px-4">
                        <div className="col-12 text-right">
                          <button className="btn_decline2">Cancel</button>
                          <button
                            className="btn_approve"
                            onClick={handleNextClick}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === 'role' && (
                  <div className="tab-pane active">
                    <h4 className="invite_heading">
                      Assign {firstName} {lastName} a role
                    </h4>
                    <p className="para3 mt-0 pb-3">
                      A team member’s role determines what they can see and do
                      on your {companyName} account.{' '}<br></br>
                      <span>Learn more about roles</span>
                    </p>
                    <div className="max_width_1000">
                      <p className="para4 mb-2">Roles</p>
                      <div className="row">
                        <div
                          className="col-12"
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                          }}
                        >
                          <input
                            type="radio"
                            name="role"
                            id="user"
                            className="roleinput"
                            value="User"
                            onChange={(e) => {
                              setRole(e.target.value)
                              setDocumentAccess(false)
                            }}
                            checked={role === 'User'}
                          />
                          <label
                            className="rolelabl d-flex align-items-center"
                            htmlFor="user"
                          >
                            <div className="radbtn me-3"></div>
                            <div>
                              <p className="rolenames mb-1">User</p>
                              <p className="rolesdiscription mb-0">
                                Basic access with limited permissions.
                              </p>
                            </div>
                          </label>

                          <input
                            type="radio"
                            name="role"
                            id="admin"
                            className="roleinput"
                            value="Admin"
                            onChange={(e) => {
                              setRole(e.target.value)
                              setDocumentAccess(false)
                            }}
                            checked={role === 'Admin'}
                          />
                          <label
                            className="rolelabl d-flex align-items-center"
                            htmlFor="admin"
                          >
                            <div className="radbtn me-3"></div>
                            <div>
                              <p className="rolenames mb-1">Admin</p>
                              <p className="rolesdiscription mb-0">
                                Full access to all settings and functions.
                              </p>
                            </div>
                          </label>

                          <input
                            type="radio"
                            name="role"
                            id="readonly"
                            className="roleinput"
                            value="Read-only"
                            onChange={(e) => {
                              setRole(e.target.value)
                              setDocumentAccess(false)
                            }}
                            checked={role === 'Read-only'}
                          />
                          <label
                            className="rolelabl d-flex align-items-center"
                            htmlFor="readonly"
                          >
                            <div className="radbtn me-3"></div>
                            <div>
                              <p className="rolenames mb-1">Read-only</p>
                              <p className="rolesdiscription mb-0">
                                Can view data but cannot make any changes.
                              </p>
                            </div>
                          </label>

                          <input
                            type="radio"
                            name="role"
                            id="docaccess"
                            className="roleinput"
                            placeholder="Select documents to share"
                            value="Document-Access"
                            onChange={(e) => {
                              setRole(e.target.value)
                              setDocumentAccess(true)
                            }}
                            checked={role === 'Document-Access'}
                          />
                          <label
                            className="rolelabl d-flex align-items-center"
                            htmlFor="docaccess"
                          >
                            <div className="radbtn me-3"></div>
                            <div>
                              <p className="rolenames mb-1">Document Access</p>
                              <p className="rolesdiscription mb-0">
                                Grant access to selected documents only
                              </p>
                            </div>
                            {/* Dropdown for document selection */}
                            {documentAccess && (
                              <div className="document-dropdown">
                                <button
                                  className="dropdown-toggle"
                                  onClick={() => setDropdownOpen(!dropdownOpen)}
                                >
                                  Select Documents
                                </button>
                                {dropdownOpen && (
                                  <div className="dropdown-content">
                                    {files.map((doc) => (
                                      <div
                                        key={doc._id}
                                        className="document-option"
                                      >
                                        <input
                                          type="checkbox"
                                          checked={selectedDocs.includes(
                                            doc._id
                                          )}
                                          onChange={() =>
                                            handleDocumentToggle(doc._id)
                                          }
                                        />
                                        <label>{doc.filename}</label>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="row pt-5 px-4 mb-5 pb-5">
                        <div className="col-12 text-right">
                          <button
                            className="btn_decline2"
                            onClick={handleBackClick}
                          >
                            Back
                          </button>
                          <button
                            className="btn_approve"
                            onClick={handleNextClick}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === 'review' && (
                  <div className="tab-pane active">
                    <h4 className="invite_heading">Review and send</h4>
                    <p className="para3 mt-0 pt-0 pb-2">
                      You can edit this role anytime from your Team settings.
                    </p>
                    <div className="max_width_1000">
                      <div className="row">
                        <div className="col-12">
                          <div className="cardreview pb-4">
                            <div className="row">
                              <div className="col-12 d-flex align-items-center">
                                <span className="intil mt-9p">
                                  {firstName?.charAt(0)} {lastName?.charAt(0)}
                                </span>
                                <div className="ms-2 ps-1">
                                  <p className="user_name d-flex align-items-end mt-0">
                                    {firstName} {lastName} &nbsp;{' '}
                                    <span className="tags_team">{role}</span>
                                  </p>
                                  <p className="user_email mt-0">{email}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mt-4">
                                <p className="parab6 mb-0">
                                  {firstName} {lastName} will be able to:
                                </p>
                                <ul className="role_ul">
                                  {role === 'Admin' && (
                                    <>
                                      <li>can upload docs</li>
                                      <li>view docs</li>
                                      <li>add other users</li>
                                    </>
                                  )}
                                  {role === 'User' && (
                                    <>
                                      <li>can view</li>
                                      <li>and upload docs</li>
                                    </>
                                  )}
                                  {role === 'Read-only' && (
                                    <>
                                      <li>can only view docs</li>
                                    </>
                                  )}
                                  {role === 'Document-Access' && (
                                    <>
                                      <li>can only see the selected docs</li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-5 px-4 mb-5 pb-5">
                        <div className="col-12 text-right">
                          <button
                            className="btn_decline2"
                            onClick={handleBackClick}
                          >
                            Back
                          </button>
                          <button
                            onClick={handleSubmitInvite}
                            className="btn_approve"
                          >
                            Invite
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Invite
