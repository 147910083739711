import React, { useState, useEffect, useContext } from 'react'
import AuthContext from '../../AuthContext'
import { BASE_URL } from '../../config'
import { BeatLoader } from 'react-spinners'
import axios from 'axios'
import '../../styles/ReadLine.css'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

const override = {
  display: 'flex',
  margin: '0 auto',
  borderColor: '#000',
  justifyContent: 'center',
}

const ReadLine = () => {
  const { userToken, userEmail, fname, lname, userId } = useContext(AuthContext)
  const [activeChat, setActiveChat] = useState(false)
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [readLineFiles, setReadLineFiles] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [fileVersions, setFileVersions] = useState({})
  const [savedChats, setSavedChats] = useState([])
  const [chatId, setChatId] = useState(null)
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedUserIdToAdd, setSelectedUserIdToAdd] = useState('')
  const [selectedUserIdToRemove, setSelectedUserIdToRemove] = useState('')
  const [chatMembers, setChatMembers] = useState([])
  const [showAddRemoveMembers, setShowAddRemoveMembers] = useState(false)
  const [isAuditView, setIsAuditView] = useState(false) // New state for audit view
  const [auditLogs, setAuditLogs] = useState([]) // New state for audit logs
  const [versionsExpanded, setVersionsExpanded] = useState(false)
  const [approvalsExpanded, setApprovalsExpanded] = useState(false)
  const [requestedApprovalsExpanded, setRequestedApprovalsExpanded] =
    useState(false)
  const [searchTerm, setSearchTerm] = useState('') // New state for search input
  const [filteredUsers, setFilteredUsers] = useState([]) // Filtered user list
  const [isChatCreator, setChatCreator] = useState(false)
  const [role, setRole] = useState('') // New state for role (User/Admin)
  const [selectedUser, setSelectedUser] = useState('') // New state for selected user
  const [description, setDescription] = useState('') // New state for description
  const [approvalStatus, setApprovalStatus] = useState('Requested') // New state for status
  const [approvals, setApprovals] = useState([])
  const [requestedApprovals, setRequestedApprovals] = useState([])
  const [approvalStatusMessage, setApprovalStatusMessage] = useState(null) // For status check result

  const [activeTab, setActiveTab] = useState("documents"); // New state for active tab

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };


  // Function to open pop-up with a specific message
  const openPopup = (message) => setApprovalStatusMessage(message)

  // Function to close pop-up
  const closePopup = () => setApprovalStatusMessage(null)

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/team-members`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })
      const { status, data } = response.data

      if (status === 1) {
        const filteredData = data.filter((member) => member.email !== userEmail)
        setTeamMembers(filteredData)
      } else {
        console.error('Error fetching team members:', response.data.message)
      }
    } catch (error) {
      console.error('Error fetching team members:', error)
    }
  }
  const checkChatCreator = async (chatId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/chat/${chatId}/checkIsCreator`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      const { status } = response.data

      if (status === 1) {
        setChatCreator(true)
      }
    } catch (error) {
      console.error('Not a chat Creater')
    }
  }

  const fetchApprovals = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/chat/approvals`,
        {
          userId: userId,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      const data = response.data.approvals.map((approval) => ({
        ...approval,
        status: approval.status || 'Requested',
      }))
      setApprovals(
        data
          .filter((app) => app.requesterId._id === userId)
          .map((app) => ({
            ...app,
            requesterName: `${app.requesterId.fname} ${app.requesterId.lname}`,
            filename: app.fileId.filename,
          }))
      )

      setRequestedApprovals(
        data
          .filter(
            (app) => app.recipientId === userId && app.status === 'Requested'
          )
          .map((app) => ({
            ...app,
            requesterName: `${app.requesterId.fname} ${app.requesterId.lname}`,
            filename: app.fileId.filename,
          }))
      )
    } catch (error) {
      console.error('Error fetching approvals:', error)
    }
  }

  useEffect(() => {
    fetchApprovals()
    fetchTeamMembers()
  }, [userToken])

  // Function to filter users based on search input
  useEffect(() => {
    if (searchTerm) {
      const filtered = teamMembers.filter((user) =>
        `${user.fname} ${user.lname} ${user.email}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
      setFilteredUsers(filtered)
    } else {
      setFilteredUsers([]) // Reset the filtered list if search is empty
    }
  }, [searchTerm, teamMembers])

  const handleAddUserToChat = async (chatId, memberId) => {
    try {
      await axios.post(
        `${BASE_URL}/chat/${chatId}/addUser/${memberId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      const addedMember = teamMembers.find((member) => member._id === memberId)
      if (addedMember) {
        setChatMembers((prev) => [...prev, addedMember])
      }
      alert('User added to chat successfully!')
      setSelectedUserIdToAdd('')
    } catch (error) {
      alert('Error adding user to chat!')
      console.error('Error adding user to chat:', error)
    }
  }

  const handleRemoveUserFromChat = async (chatId, memberId) => {
    try {
      await axios.post(
        `${BASE_URL}/chat/${chatId}/removeUser/${memberId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      setChatMembers((prev) => prev.filter((member) => member._id !== memberId))
      setSelectedUserIdToRemove('')
      alert('User removed from chat successfully!')
    } catch (error) {
      console.error('Error removing user from chat:', error)
    }
  }

  const fetchChatMembers = async (chatId) => {
    try {
      const response = await axios.get(`${BASE_URL}/chat/${chatId}/members`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })

      setChatMembers(response.data.members)
    } catch (error) {
      console.error('Error fetching chat members:', error)
    }
  }

  const fetchAuditLogs = async (chatId) => {
    // New function to fetch audit logs
    try {
      const response = await axios.get(`${BASE_URL}/chat/${chatId}/audit`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })
      setAuditLogs(response.data.logs) // Assuming response has an array of logs
    } catch (error) {
      console.error('Error fetching audit logs:', error)
    }
  }

  useEffect(() => {
    if (chatId) {
      checkChatCreator(chatId)
      fetchChatMembers(chatId)
      fetchAuditLogs(chatId)
    }
  }, [chatId])

  const fetchChatMessages = async (chatId) => {
    try {
      const response = await axios.get(`${BASE_URL}/chat/${chatId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-Type': 'application/json',
        },
      })

      let userFullName = `${fname} ${lname}`
      setMessages(
        response.data.chat.messages.map((msg) => {
          const sender = msg.sender === userFullName ? 'You' : msg.sender
          return `${sender}: ${msg.message}`
        })
      )
    } catch (error) {
      console.error('Error fetching chat messages:', error)
    }
  }

  const fetchFileVersionsForFile = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/file/versions/${fileId}`,
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      return response.data?.versions || []
    } catch (error) {
      console.error('Error fetching file versions:', error)
      return []
    }
  }

  useEffect(() => {
    let intervalId
    if (activeChat) {
      intervalId = setInterval(async () => {
        if (chatId) {
          await fetchChatMessages(chatId)
          await fetchAuditLogs(chatId)
        }
        if (selectedFile && selectedFile._id) {
          const fileVersions = await fetchFileVersionsForFile(selectedFile._id)
          setFileVersions((prev) => ({
            ...prev,
            [selectedFile._id]: fileVersions,
          }))
        }
      }, 2000)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [activeChat, chatId, selectedFile])

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await axios.post(
          `${BASE_URL}/chat/${chatId}/message`,
          { message: newMessage },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        setMessages((prev) => [...prev, `You: ${newMessage}`])
        setNewMessage('')
      } catch (error) {
        alert(error.response?.data?.message || 'Error sending message')
        console.error('Error sending message:', error)
      }
    }
  }

  const handleChatRequest = async (file) => {
    setSelectedFile(file)

    try {
      const response = await axios.post(
        `${BASE_URL}/chat`,
        { fileId: file._id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1 || response.data.status === 2) {
        // Chat successfully created or existing chat returned
        resumeChat(response.data.chat)
      }
    } catch (error) {
      console.error('Error creating or joining chat:', error)

      if (error.response?.data?.status === 1) {
        // User is not authorized to join the chat
        alert(error.response.data.message)
      } else {
        // General error
        alert('Error creating or joining chat.')
      }
    }
  }

  const resumeChat = async (chat) => {
    setChatId(chat._id)
    setSelectedFile(readLineFiles.find((file) => file._id === chat.fileId))
    setActiveChat(true)
    await fetchChatMessages(chat._id)
    await fetchChatMembers(chat._id)
    await fetchAuditLogs(chat._id)
    setIsAuditView(false)
  }

  const handleCloseChat = () => {
    setActiveChat(false)
    setMessages([])
    setSelectedFile(null)
    setSelectedVersion(null)
    setShowAddRemoveMembers(false)
    setChatMembers([])
    setAuditLogs([])
  }

  useEffect(() => {
    fetchReadLineFiles()
  }, [activeChat])

  const fetchReadLineFiles = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/user/file/readlineFiles`,
        { page: '1', size: '10' },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )
      const files = response.data?.data || []
      setReadLineFiles(files)
    } catch (error) {
      console.error('Error fetching ReadLine files:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchAllFileVersions = async () => {
    try {
      const versionPromises = readLineFiles.map(async (file) => {
        const response = await axios.get(
          `${BASE_URL}/user/file/versions/${file._id}`,
          { headers: { Authorization: `Bearer ${userToken}` } }
        )
        return { fileId: file._id, versions: response.data?.versions || [] }
      })

      const versionsData = await Promise.all(versionPromises)

      const updatedVersions = {}
      versionsData.forEach(({ fileId, versions }) => {
        updatedVersions[fileId] = versions
      })

      setFileVersions(updatedVersions)
    } catch (error) {
      console.error('Error fetching file versions:', error)
    }
  }

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true)
      await fetchReadLineFiles()
      setLoading(false)
    }

    initializeData()
  }, [userToken])

  useEffect(() => {
    if (readLineFiles.length > 0) {
      fetchAllFileVersions()
    }
  }, [readLineFiles])

  const handleRequestApproval = async (fileId) => {
    if (!selectedUser || !description) {
      alert('Please fill all fields before requesting approval.')
      return
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/chat/file/request-approval`,
        {
          fileId,
          userId: userId,
          description,
          chatId: chatId,
          recipientId: selectedUser,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      if (response.data.status === 1) {
        setApprovalStatus('Requested')
        alert('Approval request sent!')
        fetchApprovals()
      } else {
        alert(response.data.message || 'Approval request failed.')
      }
    } catch (error) {
      console.error('Error sending approval request:', error)
    }
  }

  // Fetch the status and message for a selected approval request
  const handleCheckStatus = async (fileId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/chat/file/${fileId}/approval-status`,
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      if (response.data.status === 1) {
        setApprovalStatusMessage(response.data.data)
        // setApprovalStatusMessage(response.data.data)
        // // Refresh approvals to reflect updated list
        // // Hide popup message after 3 seconds
        // setTimeout(() => setApprovalStatusMessage(null), 3000)
        openPopup(response.data.data) // Show pop-up with the status message
      } else {
        alert(response.data.message)
        openPopup(response.data.message || 'Error fetching status')
      }
    } catch (error) {
      console.error('Error fetching approval status:', error)
      openPopup('Error fetching status')
    }
  }

  // Update the message for an existing approval
  const handleSendApprovalMessage = async (fileId, status) => {
    const approval = requestedApprovals.find((app) => app.fileId._id === fileId)
    if (!approval) return
    try {
      const response = await axios.post(
        `${BASE_URL}/chat/file/update-approval-message`,
        { fileId, status: status, message: newMessage },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      )
      if (response.data.status === 1) {
        alert('Message updated successfully!')
        setNewMessage('')
        fetchApprovals()
      } else {
        alert(response.data.message)
      }
    } catch (error) {
      console.error('Error updating approval message:', error)
    }
  }

  const handleVersionChange = (file, selectedVersionId) => {
    if (selectedVersionId === file._id) {
      setSelectedVersion(null)
      setReadLineFiles((prevFiles) =>
        prevFiles.map((f) =>
          f._id === file._id ? { ...f, selectedVersion: null } : f
        )
      )
    } else {
      const selectedVer = fileVersions[file._id]?.find(
        (v) => v._id === selectedVersionId
      )
      setSelectedVersion(selectedVer)
      setReadLineFiles((prevFiles) =>
        prevFiles.map((f) =>
          f._id === file._id ? { ...f, selectedVersion: selectedVer } : f
        )
      )
    }
  }

  const handleStatusChange = (approvalId, newStatus) => {
    setApprovalStatus(newStatus)
    setRequestedApprovals((prevApprovals) =>
      prevApprovals.map((approval) =>
        approval.fileId === approvalId
          ? { ...approval, status: newStatus }
          : approval
      )
    )
  }

  const handleFileChange = async (event) => {
    if (
      !window.confirm(
        `Are you sure you want to upload ${event.target.files[0].name} as a version for ${selectedFile.filename}?`
      )
    ) {
      return
    }
    const file = event.target.files[0]
    if (!file || !selectedFile) {
      alert('Please select a file.')
      return
    }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('original_file_id', selectedFile._id)
    formData.append('chatId', chatId)

    try {
      const response = await axios.post(
        `${BASE_URL}/user/file/upload-new-version`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      if (response.data.status === 1) {
        alert('File uploaded successfully!')
        const newVersion = response.data.version
        setFileVersions((prev) => ({
          ...prev,
          [selectedFile._id]: [...(prev[selectedFile._id] || []), newVersion],
        }))
      } else {
        alert(response.data.message || 'File upload failed.')
      }
    } catch (error) {
      console.error('Error uploading new version:', error)
    }
  }

  const handleDownloadVersion = async (version) => {
    try {
      const versionId = version._id
      const url = `${BASE_URL}/user/downloadVersion?version_id=${versionId}`
      const response = await axios.get(url, {
        responseType: 'blob',
        headers: { Authorization: `Bearer ${userToken}` },
      })
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', version.filename)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (error) {
      console.error('Error downloading version:', error)
    }
  }

  const toggleView = (view) => {
    if (view === 'audit') {
      fetchAuditLogs(chatId) // Fetch audit logs when switching to audit view
    }
    setIsAuditView(view === 'audit')
  }

  return (
    <div
      className="readline-container px-4 pt-4"
      
    >

      {activeChat && (
        <div className="chat-window">
          <div className="chat-header">
            <button className="go-back2 mx-0 mt-4" onClick={handleCloseChat}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>&nbsp;
              Back
            </button>
            <h3 className='mt-3'>
              Chat with Admin about Document:{' '}
              {selectedFile?.filename?.length > 20
                ? `${selectedFile.filename.substring(0, 20)}...pdf`
                : selectedFile?.filename}
            </h3>
            <span>
              Version: {selectedVersion?.filename || selectedFile?.filename}
            </span>
          </div>
          <div className="chat-actions">

          <button className="readline-btn-down mx-1"  onClick={() => toggleView('chat')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-message-square"
              >
                <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
              Chat
            </button>
            <button className="readline-btn-select mx-1" onClick={() => toggleView('audit')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit-2"
              >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </svg>
              Audit
            </button>
            
          </div>
          <div className="chat-messages">
            {isAuditView ? (
              auditLogs.length === 0 ? (
                <p>No audit logs available.</p>
              ) : (
                auditLogs.map((log, index) => (
                  <p key={index}>
                    {new Date(log.timestamp).toLocaleString()}: {log.details}
                    {log.message ? ( // Fixed conditional check for log.message
                      <strong>
                        {' Message: '}
                        {log.message}
                      </strong>
                    ) : null}{' '}
                    {/* Use null instead of empty fragment for clarity */}
                  </p>
                ))
              )
            ) : (
              <>
                {messages.length === 0 && <p>No messages yet</p>}
                {messages.map((msg, index) => (
                  <p key={index}>{msg}</p>
                ))}
              </>
            )}
          </div>
          {!isAuditView && (
            <div className="chat-input">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Enter your message"
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          )}
        </div>
      )}

      {/* File list display */}

      <div className='cards p-4'>
          <h5 className="cards_headings">
              {activeChat ? 'Versions' : 'Redline Files'}
            </h5>
            <div className="files-list p-0" style={{ width: !activeChat && '100%' }}>
        
        {loading ? (
          <BeatLoader
            color={'#000'}
            loading={loading}
            cssOverride={override}
            size={10}
          />
        ) : activeChat ? (
          // Render versions table when activeChat is true
          <div>
            <table className="task_tabl">
              <thead>
                <tr
                  onClick={() => setVersionsExpanded(!versionsExpanded)}
                  style={{ cursor: 'pointer' }}
                >
                  <th>Version Name</th>
                  <th>Version Number</th>
                  <th>
                    Actions
                    {versionsExpanded ? (
                      <FaChevronUp style={{ marginLeft: '8px' }} />
                    ) : (
                      <FaChevronDown style={{ marginLeft: '8px' }} />
                    )}
                  </th>
                </tr>
              </thead>
              {versionsExpanded && (
                <tbody>
                  <tr key={selectedFile._id}>
                    <td>{selectedFile.filename} (Original)</td>
                    <td>1</td>
                    <td>
                      <button
                        className="readline-btn"
                        onClick={() => handleDownloadVersion(selectedFile)}
                      >
                        Download
                      </button>
                      <button
                        className="readline-btn"
                        style={{ marginTop: '0.5rem' }}
                        onClick={() =>
                          handleVersionChange(selectedFile, selectedFile._id)
                        }
                      >
                        {selectedVersion?._id === selectedFile._id ||
                        !selectedVersion
                          ? 'Selected'
                          : 'Select'}
                      </button>
                    </td>
                  </tr>
                  {fileVersions[selectedFile._id] &&
                    fileVersions[selectedFile._id].map((version, index) => (
                      <tr key={version._id}>
                        <td>{version.filename}</td>
                        <td>{index + 2}</td>
                        <td>
                          <button
                            className="readline-btn"
                            onClick={() => handleDownloadVersion(version)}
                          >
                            Download
                          </button>
                          <button
                            className="readline-btn"
                            style={{ marginTop: '0.5rem' }}
                            onClick={() =>
                              handleVersionChange(selectedFile, version._id)
                            }
                          >
                            {selectedVersion?._id === version._id
                              ? 'Selected'
                              : 'Select'}
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
            {/* Approvals Section */}
            <h5 className="cards_headings">Request for Approval</h5>
            {!approvalStatusMessage ? (
              <>
                <table className="task_tabl">
                  <thead>
                    <tr
                      onClick={() => setApprovalsExpanded(!approvalsExpanded)}
                      style={{ cursor: 'pointer' }}
                    >
                      <th>Document</th>
                      <th>Select User</th>
                      <th>Description</th>
                      <th>Request Approval</th>
                      <th>
                        Status{' '}
                        {approvalsExpanded ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </th>
                    </tr>
                  </thead>
                  {approvalsExpanded && (
                    <>
                      <tbody>
                        {readLineFiles.map((file) => (
                          <tr key={file._id}>
                            <td>{file.filename}</td>
                            {/* <td>
                        <select
                          className="dropdown mt-0 mb-0"
                          value={role}
                          onChange={(e) => setRole(e.target.value)}
                        >
                          <option value="">Select Role</option>
                          <option value="User">User</option>
                          <option value="Admin">Admin</option>
                        </select>
                      </td> */}
                            <td>
                              <select
                                className="dropdown mt-0 mb-0"
                                value={selectedUser}
                                onChange={(e) =>
                                  setSelectedUser(e.target.value)
                                }
                              >
                                <option value="">Select User</option>
                                {teamMembers
                                  // .filter((member) =>
                                  //   role === 'admin'
                                  //     ? member.permission === 'admin'
                                  //     : member.permission === 'user'
                                  // )
                                  .map((member) => (
                                    <option key={member._id} value={member._id}>
                                      {member.fname} {member.lname}
                                    </option>
                                  ))}
                              </select>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Describe request..."
                              />
                            </td>
                            <td>
                              <button
                                className="button-send"
                                onClick={() => handleRequestApproval(file._id)}
                                // disabled={approvals.status !== 'Pending'}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-send"
                                >
                                  <line x1="22" y1="2" x2="11" y2="13"></line>
                                  <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                </svg>
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn_td_tabs"
                                onClick={() => handleCheckStatus(file._id)}
                              >
                                Check Status
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </table>
              </>
            ) : (
              <div className="popup-overlay-approval">
                <div className="popup-container">
                  <button className="popup-close-button" onClick={closePopup}>
                    &times;
                  </button>
                  <p>Status: {approvalStatusMessage.status}</p>
                  <p>
                    Message: {approvalStatusMessage.message || 'No message'}
                  </p>
                </div>
              </div>
            )}
            <h5 className="cards_headings">Requested for Approval</h5>
            <table className="task_tabl">
              <thead>
                <tr
                  onClick={() =>
                    setRequestedApprovalsExpanded(!requestedApprovalsExpanded)
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <th>Document</th>
                  <th>Requester</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Message</th>
                  <th>
                    Actions{' '}
                    {requestedApprovalsExpanded ? (
                      <FaChevronUp style={{ marginLeft: '8px' }} />
                    ) : (
                      <FaChevronDown style={{ marginLeft: '8px' }} />
                    )}
                  </th>
                </tr>
              </thead>
              {requestedApprovalsExpanded && (
                <tbody>
                  {requestedApprovals.map((approval) => (
                    <tr key={approval.fileId}>
                      <td>{approval.filename}</td>
                      <td>{approval.requesterName}</td>
                      <td>{approval.description}</td>
                      <td>
                        <select
                          value={approval.status}
                          onChange={(e) =>
                            handleStatusChange(approval.fileId, e.target.value)
                          }
                        >
                          <option value="Requested">Requested</option>
                          <option value="Approved">Approved</option>
                          <option value="Rejected">Rejected</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                          placeholder="Add message..."
                        />
                      </td>
                      <td>
                        <button
                          className="button-send"
                          onClick={() =>
                            handleSendApprovalMessage(
                              approval.fileId._id,
                              approvalStatus
                            )
                          }
                          // disabled={approvals.status !== 'Pending'}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-send"
                          >
                            <line x1="22" y1="2" x2="11" y2="13"></line>
                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {/* Search Input */}
            {isChatCreator && (
              <>
                <h5 className="cards_headings">Add Users to the Chat</h5>
                <div className="chat-input">
                  <input
                    type="text"
                    placeholder="Search by name or email"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                {/* Show Users Section only if a search term is entered */}
                {searchTerm && (
                  <>
                    <table className="task_tabl">
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>Email</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.length > 0 ? (
                          filteredUsers.map((user, index) => {
                            // Check if the user is already in the chat
                            const isMember = chatMembers.some(
                              (member) => member._id === user._id
                            )
                            return (
                              <tr key={index}>
                                <td>
                                  {user.fname} {user.lname}
                                </td>
                                <td>{user.email}</td>
                                <td>
                                  {isMember ? (
                                    <button
                                      className="readline-btn"
                                      onClick={() =>
                                        handleRemoveUserFromChat(
                                          chatId,
                                          user._id
                                        )
                                      }
                                    >
                                      Remove
                                    </button>
                                  ) : (
                                    <button
                                      className="readline-btn"
                                      onClick={() =>
                                        handleAddUserToChat(chatId, user._id)
                                      }
                                    >
                                      Add
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan="3">No users found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
          </div>
        ) : readLineFiles.length === 0 ? (
          <p>No records found.</p>
        ) : (
          <table className="task_tabl">
            <thead>
              <tr>
                <th>Document Name</th>
                <th>Category</th>
                <th>Added Date</th>
                <th>Versions</th>
                {!activeChat && <th>Chat</th>}
              </tr>
            </thead>
            <tbody className="redtabletd">
              {readLineFiles.map((file) => (
                <tr key={file._id}>
                  <td>{file.filename}</td>
                  <td>{file.category_name || 'N/A'}</td>
                  <td>
                    {new Date(file.date_added_to_readline).toLocaleDateString()}
                  </td>
                  <td className="redlineselct">
                    <select
                      style={{ width: '100%' }}
                      value={file.selectedVersion?._id || file._id}
                      disabled={!activeChat || selectedFile?._id !== file._id}
                      onChange={(e) =>
                        handleVersionChange(file, e.target.value)
                      }
                    >
                      <option value={file._id}>{file.filename}</option>
                      {fileVersions[file._id] &&
                        fileVersions[file._id].map((version) => (
                          <option key={version._id} value={version._id}>
                            {version.filename}
                          </option>
                        ))}
                    </select>
                  </td>
                  {!activeChat && (
                    <td>
                      <button
                        className="readline-btn"
                        onClick={() => handleChatRequest(file)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                      </button>
                    </td>
                  )}
                </tr>
              ))}

              <tr>
                <td colSpan={5}>
                  {activeChat && (
                    <div className="d-flex justify-content-between">
                      <div
                        className="upload-new-version"
                        style={{ display: 'flex' }}
                      >
                        <input
                          type="file"
                          id="fileInput"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                        <button
                          className="readline-btn"
                          onClick={() =>
                            document.getElementById('fileInput').click()
                          }
                        >
                          Upload New Version
                        </button>
                      </div>
                      <div
                        className="upload-new-version"
                        style={{ display: 'flex' }}
                      >
                        <button
                          className="readline-btn"
                          onClick={(e) =>
                            handleDownloadVersion(
                              selectedVersion || selectedFile
                            )
                          }
                        >
                          Download selected version
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      </div>
     

       <div>

       <div className="w-100 px- cards mt-3">
          <div className="documents-container">
            <div className="doc_nav_tbas_buttons">
              {/* Navigation bar for switching between tabs */}
              <button
                onClick={() => handleTabChange("documents")}
                className={activeTab === "documents" ? "active" : ""}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>&nbsp;&nbsp;
                Redline
              </button>
              <button
                onClick={() => handleTabChange("reports")}
                className={activeTab === "reports" ? "active" : ""}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg>&nbsp;&nbsp;
                Negotitation
              </button>
            </div>

            {/* Conditional rendering based on active tab */}
            {activeTab === "documents" && (
              <div className="cards">
                <p className='text-center nodatafounds'>No Data Found</p>
              </div>
            )}

            {activeTab === "reports" && (
               <div className="cards">
               <p className='text-center nodatafounds'>No Data Found</p>
             </div>
            )}
          </div>
        </div>

       </div>
    </div>
  )
}

export default ReadLine
